<template>
  <div
    class="slogan"
    v-html="
      store.languages[store.lang]['pay-out'] ||
      '又快又稳还安全<span>冲鸭 !</span>'
    "
  ></div>
  <div style="height: 2rem; background-color: #ffffff"></div>
  <div class="flex main">
    <div class="flex main-info">
      <div class="main-info-item">
        <img src="@/dky/assets/images/icon_hd.png" alt="HD 高清" />
      </div>
      <div class="main-info-text">
        <div class="flex">
          <div class="item-bgr"></div>
          <div
            class="title"
            v-html="store.languages[store.lang]['waiter-title-1'] || 'HD 高清'"
          ></div>
        </div>
        <div
          v-html="
            store.languages[store.lang]['waiter-content-1'] ||
            '流畅观看Youtube,<br/> Netflix, HBO, Disney等<br/>流媒体内容, 全程高清。'
          "
        ></div>
      </div>
    </div>
    <div class="flex main-info">
      <div class="main-info-item">
        <img src="@/dky/assets/images/icon_7x24.png" alt="7x24不掉线" />
      </div>
      <div class="main-info-text">
        <div class="flex">
          <div class="item-bgr"></div>
          <div
            class="title"
            v-html="
              store.languages[store.lang]['waiter-title-2'] || '7x24不掉线'
            "
          ></div>
        </div>
        <div
          v-html="
            store.languages[store.lang]['waiter-content-2'] ||
            '自由使用 Instagram,<br/> Twitter, Facebook, Telegram等<br/>7x24小时不掉线。'
          "
        ></div>
      </div>
    </div>
    <div class="flex main-info">
      <div class="main-info-item">
        <img src="@/dky/assets/images/icon_nolog.png" alt="无日志" />
      </div>
      <div class="main-info-text">
        <div class="flex">
          <div class="item-bgr"></div>
          <div
            class="title"
            v-html="store.languages[store.lang]['waiter-title-3'] || '无日志'"
          ></div>
        </div>
        <div
          v-html="
            store.languages[store.lang]['waiter-content-3'] ||
            '无日志记录,<br/>您的访问记录, 只有您知道,<br/>最大程度保护您的隐私。'
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Offer",
  components: {},
  setup() {
    let store = useStore().state;

    return {
      store,
    };
  },
};
</script>

<style scoped>
* {
  background-color: #ffffff;
}
.flex {
  display: flex;
}

.slogan >>> span {
  font-weight: 900 !important;
  margin-left: 0.5rem;
}

.slogan {
  text-align: center;
  font-size: 2rem;
  font-weight: 400 !important;
}

.main > div {
  width: 32%;
}

.main {
  padding: 0 5%;
  justify-content: center;
  flex-direction: column;

  text-align: start;
  align-items: flex-start;
}
.main .main-info-item {
  display: flex;
  align-items: center;
}
.main img {
  padding: 0.5rem;
  max-width: 5rem;
}

.main > div > div:last-child {
  font-weight: 400;
  color: #333333;
  line-height: 1.5rem;
}

.main .main-info:not(:first-child) {
  margin: 0 0 0 1.5rem;
}

.main .item-bgr {
  border-radius: 6px;
  background-color: #73e7ff;
  width: 1.2rem;
  height: 1.2rem;
}

.main .main-info-text .flex {
  margin-bottom: 0.5rem;
}

.main .main-info-text {
  margin-left: 1.2rem;
}

.main .main-info-text .title {
  margin-left: 0.5rem;
}

@media all and (max-width: 419.999px) and (min-width: 150px) {
  .slogan {
    font-size: 1.5rem;
  }

  .slogan span {
    font-size: 1.5rem;
  }

  .main .main-info:not(:first-child) {
    margin: 2rem 0 0 0;
  }

  .banner .banner-img img {
    max-width: 16rem;
  }

  .main > div {
    width: 100%;
  }
}
@media all and (max-width: 499.9999px) and (min-width: 420px) {
  .main {
    align-items: center;
  }

  .main > div {
    width: 100%;
  }

  .main .main-info:not(:first-child) {
    margin: 2rem 0 0 0;
  }

  .slogan span {
    font-weight: 900;
  }
}

@media all and (max-width: 799.9999px) and (min-width: 500px) {
  .main {
    align-items: center;
  }

  .main > div {
    width: 75%;
  }

  .main .main-info:not(:first-child) {
    margin: 2rem 0 0 0;
  }
}

@media all and (max-width: 1399.9999px) and (min-width: 800px) {
  .main {
    justify-content: space-between;
    flex-direction: row;
  }
}
@media all and (max-width: 1599.9999px) and (min-width: 1400px) {
  .main {
    justify-content: space-between;
    flex-direction: row;
  }
}
@media all and (max-width: 2099.9999px) and (min-width: 1600px) {
  .main {
    justify-content: space-between;
    flex-direction: row;
  }
}

@media all and (max-width: 3000px) and (min-width: 2100px) {
  .main {
    justify-content: space-between;
    flex-direction: row;
  }
}
</style>
