<template>
  <div ref="browserimg" class="open-in-browser" :style="{ display: display }">
    <img :src="imgUrl" />
  </div>
  <div
    ref="browserall"
    class="open-in-browser overlay"
    :style="{ display: display }"
  ></div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "access",
  components: {},
  setup() {
    let display = ref("none");
    let imgUrl = ref("");

    let browserimg = ref(null);
    let browserall = ref(null);

    function checkBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) !== "micromessenger" &&
        ua.match(/WeiBo/i) !== "weibo" &&
        ua.match(/QQTheme/i) !== "qqtheme"
      ) {
        return;
      }
      display.value = "block";
      if (ua.indexOf("android") > -1) {
        imgUrl.value = require("@/dky/assets/images/open_in_browser.png");
      } else if (ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/)) {
        imgUrl.value = require("@/dky/assets/images/open_in_safari.png");
      } else {
        return;
      }
      let elements = [browserimg.value, browserall.value];
      elements.forEach((e) => {
        e.addEventListener(
          "touchstart",
          (event) => {
            event.preventDefault();
          },
          false
        );
      });
    }

    return {
      imgUrl,
      display,
      browserimg,
      browserall,
      checkBrowser,
    };
  },
  created() {
    this.checkBrowser();
  },
};
</script>

<style scoped>
.open-in-browser img {
  position: absolute;
  right: 0;
  top: 0;
  width: 75%;
  z-index: 1000;
}

.overlay {
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: alpha(Opacity=75);
  -moz-opacity: 0.75;
  opacity: 0.75;
  z-index: 100;
}
</style>
