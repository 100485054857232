<template>
  <div class="b" v-if="show">
    <div class="block">
      <span>原冲鸭加速APP</span>
      <span>将在（{{activityText}}）后彻底关闭</span>
      <span>请尽快下载新版本</span>
      <button @click="show = false">关闭</button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "Block",
  components: {},
  setup() {
    let activityText = ref('');
    let show = ref(true);

    let down = null;

    function showtime() {
      let nowtime = new Date(),
          endtime = new Date("2023/3/1");
      let lefttime = endtime.getTime() - nowtime.getTime(),
          leftd = Math.floor(lefttime/(1000*60*60*24)),
          lefth = Math.floor(lefttime/(1000*60*60)%24),
          leftm = Math.floor(lefttime/(1000*60)%60),
          lefts = Math.floor(lefttime/1000%60);
      if (lefttime < 0) {
        show.value = false;
        window.clearInterval(down);
      }
      return leftd + "天" + lefth + ":" + leftm + ":" + lefts;
    }


    onMounted(() => {
      showtime()
      down = window.setInterval(() => {
        activityText.value = showtime();
      }, 1000)
    })

    return {
      activityText,
      show
    };
  },
};
</script>

<style scoped>
.b {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block {
  width: 75%;
  padding: 1.3rem;
  border-radius: 12px;
  max-width: 600px;
  z-index: 1010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #50258b;
  color: white;
}
button {
  margin-top: 2rem;
  background-color: #ff6f00;;
  outline: none;
  border-radius: 32px;
  border: 1px solid black;
  padding: 4px 12px;
}

</style>
