<template>
  <Block></Block>
  <Access></Access>
  <div class="check-language">
    <CheckLanguage
      :istyle="'#ffffff'"
      :thislang="store.lang"
      @checkLanguage="checkLanguage"
      ref="checkLanguage"
    ></CheckLanguage>
  </div>
  <Banner></Banner>
  <div style="height: 2rem; background-color: #ffffff"></div>
  <Download></Download>
  <div style="height: 2rem; background-color: #ffffff"></div>
  <Offer></Offer>
  <Comment></Comment>
  <div style="height: 4rem; background-color: #ffffff"></div>
  <Download></Download>
  <div style="height: 2rem; background-color: #ffffff"></div>
  <Footer></Footer>
</template>

<script>
import CheckLanguage from "./components/CheckLanguage.vue";
import Access from "./components/Access.vue";
import Block from "./components/Block.vue";
import Banner from "./components/Banner.vue";
import Download from "./components/Download.vue";
import Offer from "./components/Offer.vue";
import Comment from "./components/Comment.vue";
import Footer from "./components/Footer.vue";
import utils from "./utils/utils";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    Block,
    Access,
    CheckLanguage,
    Banner,
    Download,
    Offer,
    Comment,
    Footer,
  },
  setup() {
    let stores = useStore();
    let store = stores.state;


    function checkLanguage(currentLang) {
      stores.commit("change", currentLang);
      utils.setCookie("lang", currentLang);
    }

    return {
      checkLanguage,
      store,
    };
  },
  created() {
  },
};
</script>

<style type="text/css">
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

ul {
  /*list-style: none;*/
  padding-left: 2rem;
}

a,
a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

.check-language {
  position: absolute;
  right: 0;
  top: 6px;
}
</style>
