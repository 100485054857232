<template>
  <div class="duckyPNG flex">
    <div class="flex banner">
      <div class="banner-img">
        <img src="@/dky/assets/images/ducky.png" alt="duckyVPN" />
      </div>
      <div>
        <div class="app-name langlist" langname="app-name">冲鸭加速</div>
        <div class="langlist" langname="poster1">高清不缓冲</div>
        <div class="langlist" langname="poster2">无日志</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  components: {},
};
</script>

<style scoped>
.banner {
  background-color: revert;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.banner .banner-img img {
  width: 100%;
  max-width: 20rem;
}

.banner .app-name {
  font-weight: 900;
  font-size: 2rem;
}

.duckyPNG img {
  width: 100%;
  max-width: 20rem;
  position: absolute;
  left: -0.8rem;
  top: 1rem;
  pointer-events: none;
}

.duckyPNG {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  background-image: linear-gradient(#040033, #50258b);
  background-size: cover;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 300;
  height: 18.5rem;
}

.duckyPNG > div {
  margin: 10% 10% 0 0;
}

@media all and (max-width: 419.999px) and (min-width: 150px) {
  .duckyPNG {
    height: 11rem;
  }
  .banner .banner-img img {
    max-width: 13rem;
  }
  .duckyPNG,
  .banner .app-name {
    font-size: 1.3rem;
  }
  .main > div {
    width: 100%;
  }

  .duckyPNG > div {
    justify-content: center;
    align-items: center;
    margin: 0 2rem 0 0;
    min-width: 5rem;
    max-width: 10rem;
  }
}

@media all and (max-width: 499.9999px) and (min-width: 420px) {
  .banner .banner-img img {
    left: -7%;
    top: 5%;
  }
}

@media all and (max-width: 799.9999px) and (min-width: 500px) {
  .banner .banner-img img {
    left: 8%;
    top: 5%;
  }
}
@media all and (min-width: 800px) {
  .banner .banner-img img {
    left: 8%;
    top: 5%;
    max-width: 26rem;
  }
  .duckyPNG {
    height: 24rem;
  }
}
</style>
