<template>
  <div class="download-parent download-margin">
    <a
      class="download-button download-android"
      :href="`/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.apk`"
    >
      <span>{{
        store.languages[store.lang]["download-android"] || "最新版本下载"
      }}</span>
    </a>
    <a
      @click="downloadInstall( ['download-ios'], $event)"
      class="download-button download-ios button-ios"
      :style="{ display: display }"
    >
      <span>{{
        store.languages[store.lang]["download-ios"] || "iOS 下载"
      }}</span>
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import utils from "../utils/utils";

export default {
  name: "Download",
  components: {},
  setup() {
    let display = ref("none");
    let store = useStore().state;
    function downloadInstall(className, event) {
      utils.statsDownload();
      let resp = window.install("", className);
      if (!resp) event.preventDefault();
      return resp;
    }
    return {
      display,
      store,
      downloadInstall,
    };
  },
  created() {
  },
};
</script>

<style type="text/css" scoped>
.download-parent {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  display: -webkit-flex;
  background-color: #ffffff;
}

.download-parent > a {
  display: flex;
  cursor: pointer;
  width: 50%;
  height: 3.5rem;
  max-width: 20rem;
  justify-content: center;
  background-color: #ff6f00;
  background-size: 100% 100%;
  align-items: center;
  border-radius: 32px;
  padding: 0 1rem;
}

.download-parent > a > span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #ffffff;
}

.download-parent > a > img {
  width: 1.5rem;
  height: 1.5rem;
  background-blend-mode: color-burn;
}
.download-parent > a:not(:first-child) {
  margin-top: 1rem;
}
</style>
