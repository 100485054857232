import { createApp } from "vue";
import App from "./App.vue";
// import router from "./router";
import store from "./store/store";
import VueAxios from "vue-axios";
import axios from "axios";

import utils from "./utils/utils";
utils.statsOpen();

createApp(App).use(VueAxios, axios).use(store).mount("#app");
