import { createStore } from "vuex";
import languages from "../comment/languages";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      lang: "zh",
      languages,
      env: window.env,
      isLoading: true,
    };
  },
  mutations: {
    change(state, currentLang) {
      state.lang = currentLang;
    },
    loading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
});
export default store;
